import React from 'react'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { useAdminContext } from '../../context/admin-context'
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { VIEWS } from '../../constants';
import { useNavigate } from 'react-router-dom';

import './main-header.css'

export default function MainHeader() {
  const {
    activeYear,
    setActiveYear,
    availableYears,
    activeView,
    setActiveView
  } = useAdminContext();
  const authUser = useAuthUser();
  const signOut = useSignOut()
  const navigate = useNavigate();

  const handleYearChange = (event) => {
    setActiveYear(event.target.value);
  }

  const handleViewChange = (event) => {
    setActiveView(event.target.value);
  }

  const yearOptions = availableYears.map(year => (
    <MenuItem key={year} value={year}>{year}</MenuItem>
  ));

  const viewOptions = Object.keys(VIEWS).map(view => (
    <MenuItem key={VIEWS[view].value} value={VIEWS[view].value}>{VIEWS[view].label}</MenuItem>
  ));

  return (
    <div className="main-header">

      <div className="main-header__year">
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <FormControl sx={{ minWidth: 90 }}>
            <InputLabel id="year-select-label">År</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={activeYear}
              label="År"
              onChange={handleYearChange}
              size="small"
            >
              {yearOptions}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="view-select-label">Vy</InputLabel>
            <Select
              labelId="view-select-label"
              id="view-select"
              value={activeView}
              label="Vy"
              onChange={handleViewChange}
              size="small"
            >
              {viewOptions}
            </Select>
          </FormControl>
        </Box>
      </div>

      <div className="main-header__title">
        Ringarens Hotell Administration
      </div>

      {authUser && authUser.username && (
        <div className="main-header__user">
          <span className="main-header__user__username">{authUser.username}</span>
          <Button
            size='small'
            variant="outlined"
            onClick={() => {
              signOut();
              navigate('/login')
            }}
          >
            Logga ut
          </Button>
        </div>
      )}
    </div>
  )
}
