const normalizePropertyPrices = (pricesData = []) => {
  const result = [];
  
  pricesData.forEach((item) => {
    let property = result.find((p) => p.propertyId === item.property_id);
    
    if (!property) {
      property = {
        propertyId: item.property_id,
        propertyName: item.property_name,
        defaultWeekPrice: item.default_week_price,
        customWeekPrices: []
      };
      result.push(property);
    }
    
    property.customWeekPrices.push({
      year: item.year,
      weekNumber: item.week_number,
      customWeekPrice: item.weekly_price
    });
  });

  return result;
};

export { normalizePropertyPrices };
