import { useMemo } from "react";
import clx from '../../utils/clx.js';
import { useAdminContext } from "../../context/admin-context.jsx";

export const usePropertyWeeks = ({
  propertyId,
  startWeek = 24,
  endWeek = 34
}) => {
  const {
    activeWeekNumber,
    activePropertyId,
    propertiesData,
    pricesData,
    setActiveState
  } = useAdminContext();
  const { isLoading, isError } = propertiesData;

  
  return useMemo(() => {
    if (isLoading || isError) {
      return [];
    }
    
    const priceData = Array.isArray(pricesData.prices) ? 
      pricesData.prices.filter((priceData) => {
        return priceData.propertyId === propertyId})[0] : 
      null;
    // console.log('priceData', priceData);

    const handleWeekClick = ({
      weekNumber,
      propertyId
    }) => {
      if (weekNumber) {
        if (activeWeekNumber === weekNumber && activePropertyId === propertyId) {
          setActiveState({
            activeWeekNumber: null,
            activePropertyId: null,
            activeBookingId: null,
            activeNumberOfWeeks: 1
          });
        } else {
          setActiveState({
            activeWeekNumber: weekNumber,
            activeBookingId: null,
            activePropertyId: propertyId,
            activeNumberOfWeeks: 1
          })
        }
      }
    }

    const weeks = [];

    for (let week = startWeek; week <= endWeek; week++) {
      const isActiveWeek = week === activeWeekNumber;
      const isActiveRentalProperty = propertyId === activePropertyId;
      const isActive = isActiveWeek && isActiveRentalProperty;

      const { customWeekPrice } = priceData?.customWeekPrices.find((customWeekPrice) => (customWeekPrice.weekNumber === week)) || {};
      const weekPrice = customWeekPrice || priceData?.defaultWeekPrice;
      const isDefaultPrice = !customWeekPrice;

      weeks.push(
        <div
          key={week}
          className={
            clx(
              'rental_property_details__item',
              'rental_property_details__item_week',
              {
                'rental_property_details__item--custom-price': !isDefaultPrice,
                'rental_property_details__item--selected': isActive
              }
            )
          }
          onClick={() => {
            handleWeekClick({
              weekNumber: week,
              propertyId
            });
          }}
        >
          <div className="rental_property_detail__weeknumber">{`v ${week}`}</div>
          <div className="rental_property_detail__week_price">{`${weekPrice} kr`}</div>
        </div>
      );
    }
    return weeks;
  }, [activePropertyId, activeWeekNumber, endWeek, isError, isLoading, pricesData, propertyId, setActiveState, startWeek]);
};
