import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TextField, Button, Grid, Typography, Card, CardContent } from '@mui/material';
import { API_URL } from '../../config';
import { useAdminContext } from '../../context/admin-context';

const API_ADMIN_URL = `${API_URL}/admin`;

const fetchAllAddons = async () => {
  const response = await fetch(`${API_URL}/addons`, {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    },
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Error fetching addons');
  }
  return await response.json();
};

const fetchBookingAddons = async (bookingId) => {
  const response = await fetch(`${API_ADMIN_URL}/booking-addons/${bookingId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    },
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Error fetching booking addons');
  }
  return await response.json();
};

const fetchPropertyAddons = async (propertyId) => {
  const response = await fetch(`${API_ADMIN_URL}/property-addons/${propertyId}`, {
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY
    },
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Error fetching property addons');
  }
  return await response.json();
};

const BookingAddons = ({ bookingId, propertyId }) => {
  const { reservationsData } = useAdminContext();

  const queryClient = useQueryClient();
  const { data: bookingAddonsData, isLoading: isBookingAddonsLoading } = useQuery(['bookingAddons', bookingId], () => fetchBookingAddons(bookingId), { enabled: !!bookingId });
  const { data: allAddonsData, isLoading: isAllAddonsLoading } = useQuery('allAddons', fetchAllAddons);
  const { data: propertyAddonsData, isLoading: isPropertyAddonsLoading } = useQuery(['propertyAddons', propertyId], () => fetchPropertyAddons(propertyId));
  const [bookingAddons, setBookingAddons] = useState([]);
  const [initialBookingAddons, setInitialBookingAddons] = useState([]);

  useEffect(() => {
    if (allAddonsData && propertyAddonsData) {
      const combinedAddons = allAddonsData.map((addon) => {
        const propertyAddon = propertyAddonsData.find((pa) => pa.addon_id === addon.addon_id);        const bookingAddon = bookingAddonsData ? bookingAddonsData.find((ba) => ba.addon_id === addon.addon_id) : null;
        return {
          ...addon,
          price_per_unit: propertyAddon && propertyAddon.price_per_unit !== null ? propertyAddon.price_per_unit : addon.price_per_unit,
          max_units: addon.max_units,
          booking_units: bookingAddon ? bookingAddon.quantity : 0,
        };
      });
      setBookingAddons(combinedAddons);
      setInitialBookingAddons(combinedAddons);
    }
  }, [propertyAddonsData, bookingAddonsData, allAddonsData]);

  const addBookingAddonMutation = useMutation(
    async ({ bookingId, addonId, quantity, totalPrice }) => {
      const response = await fetch(`${API_ADMIN_URL}/booking-addons`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        },
        credentials: 'include',
        body: JSON.stringify({ bookingId, addonId, quantity, totalPrice }),
      });
      if (!response.ok) {
        throw new Error('Error adding booking addon');
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bookingAddons', bookingId]);
        
        reservationsData.refetch();
      },
    }
  );

  const handleAddonChange = (addonId, value) => {
    setBookingAddons((prevAddons) =>
      prevAddons.map((addon) =>
        addon.addon_id === addonId
          ? {
              ...addon,
              booking_units: value,
            }
          : addon
      )
    );
  };

  const handleAddAllAddons = () => {
    bookingAddons.forEach((addon) => {
      if (addon.booking_units > 0 && addon.booking_units !== initialBookingAddons.find((a) => a.addon_id === addon.addon_id)?.booking_units) {
        const totalPrice = addon.booking_units * addon.price_per_unit;
        addBookingAddonMutation.mutate({
          bookingId,
          addonId: addon.addon_id,
          quantity: addon.booking_units,
          totalPrice,
        });
      }
    });
  };

  if (isAllAddonsLoading || isPropertyAddonsLoading || (bookingId && isBookingAddonsLoading)) return <Typography>Loading...</Typography>;

  return (
    <Card variant="outlined">
      <CardContent>
        {bookingAddons.map((addon) => (
          <Grid container spacing={2} key={addon.addon_id} alignItems="center" sx={{
            marginBottom: 1,
          }}>
            <Grid item xs={6}><Typography>Id.{addon.addon_id} {addon.name}</Typography></Grid>
            <Grid item xs={3}><Typography>{addon.price_per_unit} kr/st</Typography></Grid>
            <Grid item xs={3}>
              <TextField
                type="number"
                label="Antal"
                value={addon.booking_units}
                onChange={(e) => handleAddonChange(addon.addon_id, Math.min(e.target.value, addon.max_units))}
                fullWidth
                size='small'
              />
            </Grid>
          </Grid>
        ))}
        <Grid container spacing={1} justifyContent="flex-end" marginTop={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddAllAddons}
              disabled={bookingAddons.every(
                (addon) =>
                  addon.booking_units === 0 ||
                  addon.booking_units === initialBookingAddons.find((a) => a.addon_id === addon.addon_id)?.booking_units
              )}
            >
              Uppdatera tillägg
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BookingAddons;