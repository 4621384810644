import React, { useState } from 'react'
import { TextField, Button, Grid, Typography, Card, CardContent, CardActions } from '@mui/material';
import { useAdminContext } from '../../context/admin-context';

const CustomPrice = ({ propertyId }) => {
  const { pricesData, activeYear, activeWeekNumber } = useAdminContext();
  const [customPrice, setCustomPrice] = useState();

  const {
    isLoading,
    updateCustomPrice,
    deleteCustomPrice
  } = pricesData;

  const priceData = Array.isArray(pricesData.prices) ?
    pricesData.prices.filter((priceData) => {
      return priceData.propertyId === propertyId
    })[0] :
    null;

  const currentWeekCustomPrice = priceData ?
    priceData?.customWeekPrices.find((customPrice) => {
      return customPrice.weekNumber === activeWeekNumber;
    })?.customWeekPrice :
    null;

  React.useEffect(() => {
    if (currentWeekCustomPrice === null) {
      setCustomPrice('');
    } else {
      setCustomPrice(currentWeekCustomPrice);
    }
  }, [currentWeekCustomPrice])

  const handleUpdate = () => {
    updateCustomPrice({
      year: activeYear,
      propertyId,
      weekNumber: activeWeekNumber,
      customWeekPrice: customPrice
    });
  }

  const handleDelete = () => {
    deleteCustomPrice({
      year: activeYear,
      propertyId,
      weekNumber: activeWeekNumber
    });
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Hantera anpassat pris för vecka {activeWeekNumber}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              label="Anpassat pris"
              variant="outlined"
              fullWidth
              type="number"
              size='small'
              value={customPrice || ''}
              onChange={(e) => setCustomPrice(e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Ändra
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant="outlined" color="secondary" onClick={handleDelete}>
              Ta bort anpassat pris
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
  )
}

export default CustomPrice
