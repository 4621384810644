import React from 'react'
import BookingForm from '../booking-form/booking-form';
import useActiveWeek from '../../hooks/use-active-week';
import useAvailableWeeks from '../../hooks/use-available-weeks';
import PropertyDetails from '../property-details/property-details';
import { useAdminContext } from '../../context/admin-context';
import { VIEWS } from '../../constants';

import './detailed-week-view.css'

export default function DetailedWeekView() {
  const activeWeek = useActiveWeek();
  const { activeView } = useAdminContext();
  const availableWeeks = useAvailableWeeks();
  const { activeWeekNumber, activePropertyId, activeYear } = activeWeek;

  const preloadedFormData = {
    startWeek: activeWeekNumber,
    rentalPropertyId: activePropertyId,
    startYear: activeYear,
  };

  const id = activeYear + '-' + activeWeekNumber + '-' + activePropertyId;

  return (
    <div className="detailed_week_view">
      <div className="detailed_week_view__header">
        <div className="detailed_week_view__week_number">
          {`Vecka ${activeWeekNumber}`}
        </div>
        <div className="detailed_week_view__property_id">
          {`Lägenhet ${activePropertyId}`}
        </div>
      </div>

      {activeView === VIEWS.RESERVATIONS.value && (
        <div className="detailed_week_view__create_booking">
          <BookingForm
            key={id}
            numberOfWeekOptions={availableWeeks}
            preloadedFormData={preloadedFormData}
          />
        </div>
      )}

      {activeView === VIEWS.PROPERTIES.value && (
        <div className="detailed_week_view__property_details">
          <PropertyDetails />
        </div>
      )}
    </div>
  )
}
