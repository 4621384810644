import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TextField, Button, Grid, Typography, Card, CardContent } from '@mui/material';
import { API_URL } from '../../config';
import { useAdminContext } from '../../context/admin-context';

const API_ADMIN_URL = `${API_URL}/admin`;

const PropertyAdmin = ({ property }) => {
  const { pricesData } = useAdminContext();
  const queryClient = useQueryClient();
  
  const [defaultWeekPrice, setDefaultWeekPrice] = useState('');

  useEffect(() => {
    if (property) {
      setDefaultWeekPrice(property.default_week_price);
    } else {
      setDefaultWeekPrice('');
    }
  }, [property]);

  const updatePropertyMutation = useMutation(
    async (updatedProperty) => {
      const response = await fetch(`${API_ADMIN_URL}/properties/${property.id}`, {
        method: 'PUT',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(updatedProperty),
      });
      if (!response.ok) {
        throw new Error('Error updating property');
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['propertyDetails', { propertyId: property.id }]);
        pricesData.refetch();
      },
    }
  );

  const handleUpdateProperty = () => {
    updatePropertyMutation.mutate({ default_week_price: defaultWeekPrice });
  };

  return (
      <Card variant="outlined" sx={{marginTop: '12px'}}>
        <CardContent>
          <Typography variant="h5">Lägenhetsdetaljer</Typography>
          <Grid container spacing={2} marginTop={2}>
            <Grid item xs={1}>
              <TextField
                label="ID"
                value={property.id}
                InputProps={{ readOnly: true }}
                fullWidth
                disabled
                size='small'
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Lägenhet"
                value={property.name}
                InputProps={{ readOnly: true }}
                fullWidth
                disabled
                size='small'
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Standardpris per vecka"
                value={defaultWeekPrice}
                onChange={(e) => setDefaultWeekPrice(e.target.value)}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="primary" onClick={handleUpdateProperty}>
                Spara
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  );
};

export default PropertyAdmin;

// Example usage
// <PropertyAdmin propertyId={1} />;
