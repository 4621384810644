import React from 'react'
import { useAdminContext } from '../../context/admin-context'
import { usePropertyWeeks } from './use-property-weeks';

import './rental-property-details.css'

export default function RentalPropertyDetails({
  propertyId,
  startWeek,
  endWeek,
}) {

  const {
    propertiesData,
  } = useAdminContext();

  const { isLoading, isError } = propertiesData;

  const weeks = usePropertyWeeks({propertyId, startWeek, endWeek});

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Something went wrong...</div>
  }

  return (
    <div className="rental_property_details">
      {weeks.map((week) => (week))}
    </div>
  )
}
