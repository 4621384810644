import { useMemo } from "react";
import clx from '../../utils/clx.js';
import { useAdminContext } from "../../context/admin-context.jsx";

export const usePropertyWeeks = ({
  propertyId,
  startWeek = 24,
  endWeek = 34
}) => {
  const {
    activeWeekNumber,
    activePropertyId,
    activeBookingId,
    activeNumberOfWeeks,
    activeYear,
    numberOfWeekOptions,
    propertiesData,
    reservationsData,
    setActiveWeekNumber,
    setActivePropertyId,
    setActiveBookingId,
    setActiveYear,
    setActiveState
  } = useAdminContext();
  const { isLoading, isError, reservations } = reservationsData;

  return useMemo(() => {
    if (isLoading || isError) {
      return [];
    }

    const propertyReservations = reservations?.[propertyId] || [];

    const handleBookingClick = ({
      bookingId,
      weekNumber,
      propertyId,
      numberOfWeeks
    }) => {
      if (bookingId) {
        if (activeBookingId === bookingId) {
          setActiveState({
            activeWeekNumber: null,
            activePropertyId: null,
            activeBookingId: null,
            activeNumberOfWeeks: 1
          });
        } else {
          setActiveState({
            activeWeekNumber: weekNumber,
            activeBookingId: bookingId,
            activePropertyId: propertyId,
            activeNumberOfWeeks: 1
          })
        }
      }
    }

    const handleWeekClick = ({
      weekNumber,
      propertyId
    }) => {
      if (weekNumber) {
        if (activeWeekNumber === weekNumber && activePropertyId === propertyId) {
          setActiveState({
            activeWeekNumber: null,
            activePropertyId: null,
            activeBookingId: null,
            activeNumberOfWeeks: 1
          });
        } else {
          setActiveState({
            activeWeekNumber: weekNumber,
            activeBookingId: null,
            activePropertyId: propertyId,
            activeNumberOfWeeks: 1
          })
        }
      }
    }

    const weeks = [];

    let isOverlapping = 0;

    for (let week = startWeek; week <= endWeek; week++) {
      const booking = propertyReservations.find((reservation) => reservation.startWeek === week && reservation.startYear === activeYear);


      if (booking) {
        const numberOfWeeks = booking.endWeek - booking.startWeek + 1;
        const isActive = activeBookingId === booking.id;

        weeks.push(
          <div
            key={week}
            className={
              clx(
                'rental_property_bookings__item',
                'rental_property_bookings__item_booking',
                {
                  'rental_property_bookings__item--selected': isActive
                }
              )
            }
            onClick={() => {
              handleBookingClick({
                bookingId: booking.id,
                weekNumber: week,
                propertyId,
                numberOfWeeks
              });
            }}
            style={{
              gridColumnStart: week - startWeek + 1,
              gridColumnEnd: `span ${numberOfWeeks}`
            }}
          >
            <div>{`v ${week}`}</div>
            {booking.description && (
              <div className="booking_description">
                {booking.description}
              </div>
            )}

            <div>{`Bokning: ${booking.id}`}</div>
          </div>
        );

        week += numberOfWeeks - 1; // skip over weeks being spanned
      } else {
        const isActiveWeek = week === activeWeekNumber;
        const isActiveRentalProperty = propertyId === activePropertyId;
        const isActive = isActiveWeek && isActiveRentalProperty;

        if (isOverlapping > 0) {
          isOverlapping--;
        } else {
          weeks.push(
            <div
              key={week}
              className={
                clx(
                  'rental_property_bookings__item',
                  'rental_property_bookings__item_week',
                  {
                    'rental_property_bookings__item--selected': isActive
                  }
                )
              }
              onClick={() => {
                handleWeekClick({
                  weekNumber: week,
                  propertyId
                });
              }}
              style={{
                gridColumnStart: week - startWeek + 1,
                gridColumnEnd: `span ${isActive ? activeNumberOfWeeks : 1}`
              }}
            >
              {`v ${week}`}
            </div>
          );
  
          if (isActive && activeNumberOfWeeks > 1) {
            isOverlapping = activeNumberOfWeeks - 1;
          }
        }
      }
    }
    return weeks;
  }, [isLoading, isError, reservations, propertyId, activeBookingId, setActiveState, activeWeekNumber, activePropertyId, startWeek, endWeek, activeYear, activeNumberOfWeeks]);
};
