import React from 'react'
import { useAdminContext } from '../../context/admin-context'
import { VIEWS } from '../../constants';
import './week-matrix.css';
import RentalPropertyBookings from '../rental-property-bookings/rental-property-bookings';
import RentalPropertyDetails from '../rental-property-details/rental-property-details';

export default function WeekMatrix() {
  const {
    activeView,
    propertiesData,
  } = useAdminContext();

  const { properties, isLoading } = propertiesData;

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="week_matrix">
      {properties.map(({ id }) => {
        if (activeView === VIEWS.RESERVATIONS.value) {
          return (
            <RentalPropertyBookings
              propertyId={id}
              key={id}
            />
          )
        }

        if (activeView === VIEWS.PROPERTIES.value) {
          return (
            <RentalPropertyDetails
              propertyId={id}
              key={id}
            />
          )
        }
        
        return null;
      })}
    </div>
  )
}
